import { TextField } from "@mui/material";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";

interface globalFilterProps {
  globalFilter: any;
  setGlobalFilter: any;
}

const GlobalFilter: React.FC<globalFilterProps> = ({
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextField
      sx={{ marginLeft: "auto", paddingRight: "1rem", width: "50%" }}
      label="Filtrar Datos"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

export default GlobalFilter;
