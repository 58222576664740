import { useEffect, useState } from 'react'
import { TextField, Alert, MenuItem, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TypeOf, z } from 'zod'
import axios from 'axios'
import { API_URL } from '../../app/constants'
import { useCookies } from 'react-cookie'

const registerSchema = z.object({
  seller: z.string().min(1, { message: 'Campo Obligatorio' }),
  transaction: z.string().min(1, { message: 'Campo Obligatorio' }),
  reason: z.string().min(1, { message: 'Campo Obligatorio' }),
  authorization: z.string().min(1, { message: 'Campo Obligatorio' }),
})

const transactionType = [
  {
    key: 'NCRE',
    value: 'Nota de Credito',
  },
  {
    key: 'NDEB',
    value: 'Nota de Debito',
  },
  {
    key: 'NABN',
    value: 'Nota de Abono',
  },
  {
    key: 'ANULC',
    value: 'Anulación Nota de Credito',
  },
  {
    key: 'ANULD',
    value: 'Anulacion Nota de Debito',
  },
  {
    key: 'ANULA',
    value: 'Anulacion Nota de Abono',
  },
  {
    key: 'ANULB',
    value: 'Anulacion de Factura',
  },
]

type RegisterInput = TypeOf<typeof registerSchema>
export const PasswordGenerator = () => {
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationType, setNotificationType] = useState<any>(undefined)
  const [listOfUsers, setListOfUsers] = useState<any[]>([])
  const [cookie] = useCookies(['access'])
  const [nickname] = useCookies(['nickname'])

  const methods: any = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  })

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { isSubmitSuccessful, errors },
  } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  useEffect(() => {
    if (notificationType) {
      setTimeout(() => setNotificationType(undefined), 8000)
    }
  }, [notificationType])

  useEffect(() => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie['access']}` },
    }
    axios
      .post(`${API_URL}/query/all-users`, {}, apiAccess)
      .then((response: any) => {
        setListOfUsers([...response.data.rows])
      })
      .catch((error) => {
        setNotificationType('error')
        setNotificationMessage(error)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [cookie])

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie['access']}` },
    }
    axios
      .post(
        `${API_URL}/external/passwordGenerator`,
        {
          adminuser: nickname['nickname'],
          seller: values.seller,
          reason: values.reason,
          transaction: values.transaction,
          authdte: values.authorization,
        },
        apiAccess
      )
      .then((response: any) => {
        setNotificationType('success')
        setNotificationMessage(
          'El codigo de autorizacion se ha generado de manera exitosa'
        )
        console.log(response)
      })
      .catch((error) => {
        setNotificationType('error')
        setNotificationMessage(
          error.request.response
            ? JSON.parse(error.request.response).message
            : 'Error inesperado, contacte con IT CODIGO 500'
        )
      })
  }

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ m: '1rem' }}>
        Autorización de Transacciones
      </Typography>
      <FormProvider {...methods}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '50%',
              }}
            >
              <Controller
                control={control}
                defaultValue=''
                name={'seller'}
                render={({ field }) => (
                  <TextField
                    label='Usuario'
                    select
                    error={!!errors['seller']}
                    helperText={
                      errors['seller'] ? errors['seller'].message : ''
                    }
                    {...register('seller')}
                    {...field}
                    required
                  >
                    {listOfUsers.map((element) => (
                      <MenuItem key={element.nickname} value={element.nickname}>
                        {element.nickname}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                defaultValue=''
                name={'transaction'}
                render={({ field }) => (
                  <TextField
                    label='Tipo de Transaccion'
                    {...field}
                    select
                    required
                    error={!!errors['transaction']}
                    helperText={
                      errors['transaction'] ? errors['transaction'].message : ''
                    }
                    {...register('transaction')}
                    sx={{ display: 'flex' }}
                  >
                    {transactionType.map((element) => (
                      <MenuItem key={element.key} value={element.key}>
                        {element.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                defaultValue=''
                name='authorization'
                render={() => (
                  <TextField
                    label='Número de Autorización'
                    required
                    autoComplete='off'
                    error={!!errors['authorization']}
                    helperText={
                      errors['authorization']
                        ? errors['authorization'].message
                        : ''
                    }
                    {...register('authorization')}
                    sx={{ display: 'flex' }}
                  ></TextField>
                )}
              />
              <Controller
                control={control}
                defaultValue=''
                name={'reason'}
                render={() => (
                  <TextField
                    multiline
                    rows={4}
                    label='Justificacion/Comentario acerca de esta Transaccion'
                    error={!!errors['reason']}
                    helperText={
                      errors['reason'] ? errors['reason'].message : ''
                    }
                    {...register('reason')}
                    sx={{ display: 'flex' }}
                  ></TextField>
                )}
              />
              <LoadingButton variant='contained' type='submit'>
                Generar
              </LoadingButton>
              {notificationType && (
                <Alert
                  sx={{ marginTop: '1rem' }}
                  severity={notificationType}
                  variant='filled'
                >
                  {notificationMessage}
                </Alert>
              )}
            </Box>
          </form>
        </Box>
      </FormProvider>
    </>
  )
}
