import { useState, useEffect } from 'react'

import axios from 'axios'
import { API_URL } from '../app/constants'
import { useCookies } from 'react-cookie'

const useFetchNoteReport = () => {
  const [data, setData] = useState<any[]>([])
  const [status, setStatus] = useState<string>('inactive')
  const [error, setError] = useState()
  const [cookie] = useCookies(['access'])
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie['access']}` },
  }

  useEffect(() => {
    setStatus('loading')
    async function fetchData() {
      await axios
        .post(`${API_URL}/query/note-report`, {}, apiAccess)
        .then((response) => {
          setData(response.data.rows)
          setError(undefined)
          setStatus('ok')
        })
        .catch((error) => {
          setError(error)
          setStatus('error')
        })
    }

    fetchData()
  }, [cookie])

  return [data, status, error]
}

export default useFetchNoteReport
