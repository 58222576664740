import { Button } from "@mui/material";
import { useCookies } from "react-cookie";
import { API_URL } from "../../app/constants";

interface renderReportProps {
  url: string;
}

const RenderReport: React.FC<renderReportProps> = ({ url }) => {
  const [cookie] = useCookies(["access"]);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };

  const showReport = () => {
    fetch(`${API_URL}/${url}`, apiAccess)
      .then((response) => response.blob())
      .then((blob) => {
        let _url: any = window.URL.createObjectURL(blob);
        window.open(_url, "_blank")?.focus();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Button variant="contained" onClick={showReport} size="small">
      Ver
    </Button>
  );
};

export default RenderReport;
