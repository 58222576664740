import styles from "./Annulment.module.css";

import {
  ResponsiveGridLayout,
  Input,
  Icon,
  Button,
  Panel,
  MessageBoxActions,
  MessageBox,
  Dialog,
} from "@ui5/webcomponents-react";
import { TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { AnnulmentDTO, annulmentInitialState } from "../../models/AnnulmentDTO";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import { useCookies } from "react-cookie";
import { AnnulmentComponent } from "../../components/annulment/AnnulmentComponent";
import { SnackBar } from "../../components/snack-bar/SnackBar";
import { DynamicTable } from "../../components/dynamic-table/DynamicTable";

const LAST_ENABLE_DAY_OF_MONTH = 10;
export function Annulment() {
  /**
   * Annulment global props
   */
  const [annulmentRequireElevation, setAnnulmentRequireElevation] =
    useState<number>(0);
  const [notes, setNotes] = useState<any[]>([]);
  const [authToken, setAuthToken] = useState<string>("");
  const [authorizationNumberSearch, setAuthorizationNumberSearch] =
    useState<string>("");
  const [annulment, setAnnulment] = useState<AnnulmentDTO>({
    ...annulmentInitialState,
  });
  const [annulmentReasons, setAnnulmentReasons] = useState<any[]>([]);
  const showMessage = useRef<Ui5DialogDomRef>(null);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [createAnnulmentConfirmation, setCreateAnnulmentConfirmation] =
    useState(false);
  const [annuling, setAnnuling] = useState(false);
  const [cookie] = useCookies(["access"]);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };
  const [nickname] = useCookies(["nickname"]);

  /* notifications required states */
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [notificationType, setNotificationType] = useState<any>(undefined);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  /**
   * Gets initial data for global props
   */
  useEffect(() => {
    const source = axios.CancelToken.source();
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    const fetchMasterData = async () => {
      try {
        const annulmentReasonsResponse = await axios.get(
          `${API_URL}/crud/annulmentReason`,
          { cancelToken: source.token, ...apiAccess }
        );
        setAnnulmentReasons(annulmentReasonsResponse.data);
      } catch (error) {}
    };
    fetchMasterData();
    return () => {
      source.cancel();
    };
  }, [cookie]);

  /**
   * Restores annulment initial state
   */
  const restoreInitialState = () => {
    setAuthorizationNumberSearch("");
    setAnnulment({ ...annulmentInitialState });
  };

  /**
   * Searches exchange bill data
   * @param documentSeries Document series
   * @param documentNumber Document number
   * @param authorizationNumber Document authorization number
   * @returns Echange bill data
   */
  const searchBillRequest = async (authorizationNumber: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/search-bill`,
        {
          authorizationNumber: authorizationNumber,
        },
        apiAccess
      );
      return [response.data.rows[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   *
   * @param documentSeries
   * @param documentNumber
   * @param authorizationNumber
   * @returns
   */
  const searchNoteRequest = async (authorizationNumber: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/search-note`,
        {
          authorizationNumber: authorizationNumber,
        },
        apiAccess
      );
      return [response.data.rows[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Verify if the document can be cancelled, depending on its emission date.
   * @param emissiondate
   */
  const isBillEnableOnCurrentDate = async (emissiondate: string) => {
    const currentDate = new Date();
    if (currentDate.getDate() > LAST_ENABLE_DAY_OF_MONTH) {
      let emissionDate = new Date(emissiondate);
      if (currentDate.getMonth() - emissionDate.getMonth() !== 0) {
        setAnnulmentRequireElevation(1);
        setNotificationType("warning");
        setNotificationMessage(
          "La factura esta fuera del plazo normal para autorizar la anulacion\nSolicite un token de autorizacion para continuar"
        );
        setShowNotification(true);
      } else {
        setAnnulmentRequireElevation(0);
      }
    }
  };

  /**
   *
   * @param authorizationNumber
   * @returns list of fiscal notes associated with the bill
   */
  const getBillNotes = async (authorizationNumber: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/get-notes-bill`,
        { authorizationNumber: authorizationNumber },
        apiAccess
      );
      return [response.data.rows, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Searches annulment data
   * @param documentSeries Document series
   * @param documentNumber Document number
   * @param authorizationNumber Document authorization number
   * @returns Annulment data data
   */
  const searchAnnulmentRequest = async (authorizationNumber: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/search-annulment`,
        {
          authorizationNumber: authorizationNumber,
        },
        apiAccess
      );
      return [response.data.rows[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Handles annument search
   */
  const handleSearchDocument = async () => {
    const [annulmentData] = await searchAnnulmentRequest(
      authorizationNumberSearch
    );
    // DTE annulment already exists
    if (annulmentData) {
      setAnnulment({ ...annulment, ...annulmentData });
      setAnnulmentRequireElevation(0);
      return;
    }

    const [billData] = await searchBillRequest(authorizationNumberSearch);
    if (billData) {
      const [notes] = await getBillNotes(billData.authorizationnumber);
      if (notes.length > 0) {
        setNotes(notes);
        setAnnulmentRequireElevation(3);
        return;
      }

      isBillEnableOnCurrentDate(billData.emissiondate);
      setAnnulment({ ...annulment, ...billData, annulmentid: 0 });
      return;
    }

    const [noteData] = await searchNoteRequest(authorizationNumberSearch);
    if (noteData) {
      console.log(noteData);
      setAnnulment({ ...annulment, ...noteData, annulmentid: 0 });
      setAnnulmentRequireElevation(2);
      return;
    }

    restoreInitialState();
  };

  /**
   * Handlers annulment reason name
   * @param annulmentReasonName Annulment reason name
   */
  const handleAnnulmentReasonChange = (annulmentReasonName: string) => {
    for (let i = 0; i < annulmentReasons.length; i++) {
      const annulmentReason = annulmentReasons[i];
      if (annulmentReason.name.toString() === annulmentReasonName) {
        setAnnulment({
          ...annulment,
          annulmentreasonid: annulmentReason.annulmentreasonid,
        });
        return;
      }
    }
  };

  /**
   * Gets annulment reason name
   * @returns Annulment reason name
   */
  const getAnnulmentReasonValue = (): string => {
    if (annulment.annulmentid !== 0) {
      for (let i = 0; i < annulmentReasons.length; i++) {
        const annulmentReason = annulmentReasons[i];
        if (annulmentReason.annulmentreasonid === annulment.annulmentreasonid) {
          return annulmentReason.name;
        }
      }
    }
    return "";
  };

  /**
   * Handles annulment request
   */
  const annulmentRequest = async (annulmentData: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/external/dteannulment/annulment`,
        annulmentData,
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Handles annulment operation
   */
  const handleAnnulment = async () => {
    if (annulment.annulmentid !== 0) {
      viewFile(
        `${API_URL}/external/annulment/${annulment.billtype}/${annulment.annulmentid}`
      );
      return;
    }
    if (annulment.annulmentreasonid === 0) {
      displayMessage("Error", "Selecciona el motivo de anulación");
      return;
    }
    setCreateAnnulmentConfirmation(true);
  };

  /**
   * Handles combobox billing confirmation
   * @param event Message box confirmation
   */
  const handleCreateAnnulmentConfirmation = async (event: any) => {
    if (event.detail.action === MessageBoxActions.OK) {
      setCreateAnnulmentConfirmation(false);
      setAnnuling(true);
      const annulmentData: any = { ...annulment };
      delete annulmentData.annulmentid;
      delete annulmentData.annulmentdate;
      const [annulmentRequestData] = await annulmentRequest(annulmentData);
      if (!annulmentRequestData) {
        displayMessage(
          "Error anulando documento",
          "Comunicate con el soporte IT."
        );
        setAnnuling(false);
        return;
      }
      setAnnulment({ ...annulment, ...annulmentRequestData.data[0] });
      displayMessage(
        "Anulación generada",
        `Anulación #${annulmentRequestData.data[0].annulmentid} generada.`
      );
      setAnnuling(false);
    }
    setCreateAnnulmentConfirmation(false);
  };

  /**
   * Displays a dialog message
   * @param title Message title
   * @param content Message content
   */
  const displayMessage = (title: string, content: string) => {
    setMessageTitle(title);
    setMessageContent(content);
    showMessage.current?.show();
  };

  /**
   * Displays annulment rendered data
   */
  const showAnnulment = () => {
    if (annulment.annulmentid !== 0) {
      if (annulment.billtype === "FCAM" || annulment.billtype === "FACT") {
        viewFile(
          `${API_URL}/external/annulment/${annulment.billtype}/${annulment.annulmentid}`
        );
      } else {
        viewFile(
          `${API_URL}/external/notes/note-annulment-report/${annulment.billtype}/${annulment.documentid}`
        );
      }
    }
  };

  /**
   * Prints annulment rendered data
   */
  const printAnnulment = () => {
    if (annulment.annulmentid !== 0) {
      if (annulment.billtype === "FCAM" || annulment.billtype === "FACT") {
        viewFile(
          `${API_URL}/external/annulmentPrint/${annulment.billtype}/${annulment.annulmentid}`
        );
      } else {
        viewFile(
          `${API_URL}/external/notes/note-annulment-report/${annulment.billtype}/${annulment.documentid}`
        );
      }
    }
  };

  /**
   * Opens an file
   * @param url File url
   */
  const viewFile = async (url: any) => {
    fetch(url, apiAccess)
      .then((response) => response.blob())
      .then((blob) => {
        let _url: any = window.URL.createObjectURL(blob);
        window.open(_url, "_blank")?.focus();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   *
   * @returns
   */
  const handleSubmitAuthorization = async () => {
    await axios
      .post(
        `${API_URL}/external/verifyAuthentication`,
        {
          passwordauth: authToken,
          authdte: authorizationNumberSearch,
          transactiontype: mapDteType(annulment.billtype),
          seller: nickname["nickname"],
        },
        apiAccess
      )
      .then((success) => {
        console.log(success);
        if (success) {
          setAnnulmentRequireElevation(0);
        }
      })
      .catch((error) => {
        setNotificationType("error");
        setNotificationMessage(error.response.data.message);
        setShowNotification(true);
      });
  };

  const mapDteType = (currentType: string): string => {
    switch (currentType) {
      case "NCRE":
        return "ANULC";
      case "NDEB":
        return "ANULD";
      case "NABN":
        return "ANULA";
      case "FCAM":
        return "ANULB";
      case "FACT":
        return "ANULB";
      default:
        return "";
    }
  };

  return (
    <div className={styles.annulment}>
      <Panel headerText="Búsqueda de DTE">
        <ResponsiveGridLayout
          columnsXL={2}
          columnsL={2}
          columnsM={1}
          columnsS={1}
        >
          <div>
            Número de Autorización
            <Input
              icon={<Icon name="number-sign" />}
              onInput={(e) =>
                setAuthorizationNumberSearch(e.target.value.toString())
              }
              className={styles.input}
              placeholder="Número de Autorización"
              value={authorizationNumberSearch}
            />
            <Button
              icon="search"
              onClick={handleSearchDocument}
              className={styles.button}
            >
              Buscar Documento
            </Button>
          </div>
          <div></div>
        </ResponsiveGridLayout>
        {annulmentRequireElevation === 0 && (
          <AnnulmentComponent
            annulment={annulment}
            setAnnulment={setAnnulment}
            handleAnnulmentReasonChange={handleAnnulmentReasonChange}
            getAnnulmentReasonValue={getAnnulmentReasonValue}
            annulmentReasons={annulmentReasons}
            handleAnnulment={handleAnnulment}
            annuling={annuling}
            showAnnulment={showAnnulment}
            printAnnulment={printAnnulment}
          />
        )}
        {annulmentRequireElevation === 2 && (
          <ResponsiveGridLayout
            columnsXL={2}
            columnsL={2}
            columnsM={1}
            columnsS={1}
          >
            <div>
              <h2>Autorización Requerida</h2>
              <p>
                <b>
                  Una vez que ingrese la clave de Autorización esta sera
                  desactivada y no podra usarla de nuevo.
                </b>
              </p>
              <TextField
                label="Ingresa aquí el número de Autorización"
                variant="filled"
                className={styles.input}
                onChange={(e) => setAuthToken(e.target.value)}
              />
              <Button
                className={styles.button}
                design="Emphasized"
                onClick={handleSubmitAuthorization}
              >
                Enviar Autorización
              </Button>
            </div>
            <div></div>
          </ResponsiveGridLayout>
        )}
        {annulmentRequireElevation === 3 && (
          <div>
            <h2>
              No se puede anular la factura, ya que posee los siguientes
              documentos ligados
            </h2>
            <p>
              Para poder continuar con la anulación, se deben anular todos los
              documentos ligados.
            </p>
            <DynamicTable
              headers={["Número de Autorización", "Tipo", "Descripcion"]}
              tableContent={notes}
            />
          </div>
        )}

        <SnackBar
          message={notificationMessage}
          open={showNotification}
          setOpen={setShowNotification}
          severity={notificationType}
        />
        <Dialog
          ref={showMessage}
          footer={
            <div style={{ padding: "5px" }}>
              <Button
                onClick={() => showMessage.current?.close()}
                design="Emphasized"
              >
                Cerrar
              </Button>
            </div>
          }
          headerText={messageTitle}
        >
          <div style={{ padding: "5px" }}>{messageContent}</div>
        </Dialog>
        <>
          <MessageBox
            open={createAnnulmentConfirmation}
            onClose={handleCreateAnnulmentConfirmation}
            actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
          >
            ¿Desea anular el documento?
          </MessageBox>
        </>
      </Panel>
    </div>
  );
}
