import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import GlobalFilter from "../../components/global-filter/GlobalFilter";
import Pagination from "../../components/pagination/Pagination";

interface propsReportTable {
  columns: any;
  data: any;
  initialState: any;
}

const TableReport: React.FC<propsReportTable> = ({
  columns,
  data,
  initialState,
}) => {
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,

    // pagination props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    //filter
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = useTable(
    {
      columns,
      data,
      initialState: { ...initialState, pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Box>
      <Table {...getTableProps}>
        <TableHead>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header") ? column.render("Header") : ""}
                </TableCell>
              ))}
            </TableRow>
          ))}{" "}
        </TableHead>
        <TableBody>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default TableReport;
