import { useEffect, useState } from "react";
import { API_URL } from "../../app/constants";
import axios from "axios";
import { useCookies } from "react-cookie";
import MainLogo from "../../assets/main-logo-2.png";
import { AppBar, Box, Typography } from "@mui/material";

export function Header() {
  const [cookie] = useCookies(["access"]);
  const [todayExchange, setTodayExchange] = useState("");

  useEffect(() => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    if (todayExchange === "") {
      if (cookie["access"] !== undefined) {
        axios
          .get(`${API_URL}/external/exchange`, apiAccess)
          .then((exchange: any) => {
            setTodayExchange(exchange.data.ratio);
          });
      }
    }
  }, [todayExchange, cookie]);

  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result
      .toLocaleString("es-GT", { timeZone: "America/Guatemala" })
      .split(" ")[0];
  };
  return (
    <AppBar position="static" sx={{ backgroundColor: "#4974A9" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img alt="logo image" src={MainLogo} width="120px" height="auto" />
        <Typography variant="body2">
          {`Ciudad de Guatemala, ${cleanDate(
            new Date()
          )}, v3.1.1  Cambio: 1.00 USD = ${todayExchange} QTZ`}
        </Typography>
      </Box>
    </AppBar>
  );
}
