// import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Table from './Table'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  CircularProgress,
} from '@mui/material'

import useFetchNoteReport from '../../hooks/fetchNoteReport'
import { useMemo } from 'react'
import RenderReport from '../../components/render-report/RenderReport'

export const NotesReport = () => {
  const history = useHistory()
  const [data, status, error] = useFetchNoteReport()
  const cdata = useMemo(() => data, [data])
  const columns = useMemo(
    () => [
      {
        Header: 'noteid',
        accessor: 'noteid',
      },
      {
        Header: 'annulmentid',
        accessor: 'annulmentid'
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ value }: any) => String(value) === 'true' ? 'Activo' : 'Anulado'
      },
      {
        Header: 'Autorizacion',
        accessor: 'authorizationnumber',
      },
      {
        Header: 'Tipo de Nota',
        accessor: 'notetype',
      },
      {
        Header: 'Motivo de Ajuste',
        accessor: 'ajuste',
      },
      {
        Header: 'Fecha de Certificacion',
        id: 'certificationdate',
        accessor: 'certificationdate',
      },
      {
        Header: 'Moneda',
        accessor: 'currency',
      },
      {
        Header: 'Total',
        accessor: 'grantotal',
      },
      {
        Header: 'IVA',
        accessor: 'totalwithvat',
      },
      {
        Header: 'Nit del Cliente',
        accessor: 'receivernit',
      },
      {
        Header: 'Nombre del Cliente',
        accessor: 'receiverfullname',
      },
      {
        Header: 'Correo del Cliente',
        accessor: 'receiveremail',
      },
      {
        Header: 'Autorizacion Documento de Origen',
        accessor: 'numeroautorizaciondocumentoorigen'

      },
      {
        Header: 'Acciones',
        Cell: ({ cell }: any) => (
          <>
            {cell.row.values.status ? (
              <RenderReport
                url={`external/notes/note-report/${cell.row.values.notetype}/${cell.row.values.noteid}`}
              />
            ) : (
              <RenderReport
                url={`external/notes/note-annulment-report/${cell.row.values.notetype}/${cell.row.values.noteid}`}
              />
            ) }
          </>
        ),
      },
    ],
    []
  )

  if (status === 'loading') {
    return (
      <>
        <Button
          onClick={() => history.push('/reports')}
          variant='contained'
          startIcon={<ArrowBackIcon />}
          sx={{ margin: '15px' }}
        >
          Atras
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={status === 'loading'}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    )
  }

  if (status === 'error') {
    return (
      <>
        <Button
          onClick={() => history.push('/reports')}
          variant='contained'
          startIcon={<ArrowBackIcon />}
          sx={{ margin: '15px' }}
        >
          Atras
        </Button>
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          Ha ocurrido un error al obtener los datos del reporte x( —{' '}
          <strong>Comunicate con IT</strong>
        </Alert>
      </>
    )
  }

  return (
    <div>
      <Button
        onClick={() => history.push('/reports')}
        variant='contained'
        startIcon={<ArrowBackIcon />}
        sx={{ margin: '15px' }}
      >
        Atras
      </Button>
      <Table
        columns={columns}
        data={cdata}
        initialState={{ hiddenColumns: ['noteid', 'annulmentid'] }}
      />
    </div>
  )
}
