import styles from "./Customer.module.css";
import {
  Input,
  Icon,
  ComboBox,
  ComboBoxItem,
  Form,
  FormItem,
  FormGroup,
  TextArea,
} from "@ui5/webcomponents-react";

import Button from "@mui/material/Button";

import SearchIcon from "@mui/icons-material/Search";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import axios from "axios";
import { API_URL } from "../../app/constants";
import { useEffect, useState, useRef } from "react";
import { CustomerDTO, customerInitialState } from "../../models/CustomerDTO";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import { useCookies } from "react-cookie";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SnackBar } from "../../components/snack-bar/SnackBar";
import { DialogComponent } from "../../components/dialog-component/DialogComponent";
import { Modal } from "@mui/material";

import { Box } from "@mui/system";
import { DynamicTable } from "../../components/dynamic-table/DynamicTable";

export function Customer() {
  /**
   * Customer global state and searching
   */
  const [customer, setCustomer] = useState<CustomerDTO>(customerInitialState);
  const [customerSearch, setCustomerSearch] = useState<string>("");
  const [customers, setCustomers] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<string>("");
  const [idCustomerType, setIdCustomerType] = useState<number>(0);

  /**
   * Customer DTO
   */
  const [similarCustomers, setSimilarCustomers] = useState<any[]>([]);

  /**
   * Customer properties
   */
  const [customerTypes, setCustomerTypes] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);
  const [creditDays, setCreditDays] = useState<any[]>([]);
  const showMessage = useRef<Ui5DialogDomRef>(null);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [severity, setSeverity] = useState<any>(undefined);
  const [messageContent, setMessageContent] = useState<string>("");
  const [cookie] = useCookies(["access"]);
  const [userCookie] = useCookies(["user"]);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };

  /**
   * Load initial data to global props
   */
  useEffect(() => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    axios
      .get(`${API_URL}/crud/customerType`, apiAccess)
      .then((response: any) => {
        const serverCustomerTypes = response.data.map((customerType: any) => (
          <ComboBoxItem
            text={customerType.description}
            key={customerType.customertypeid}
          />
        ));
        setCustomerTypes(serverCustomerTypes);
      });
    axios.get(`${API_URL}/crud/seller`, apiAccess).then((response: any) => {
      const serverSellers = response.data.map((seller: any) => (
        <ComboBoxItem
          key={seller.sellerid}
          text={`${seller.sellercode} - ${seller.fullname}`}
        />
      ));
      setEmployees(serverSellers);
    });
    axios.get(`${API_URL}/crud/creditdays`, apiAccess).then((response: any) => {
      const serverCreditDays = response.data.map((creditday: any) => (
        <ComboBoxItem text={creditday.days} key={creditday.creditdaysid} />
      ));
      setCreditDays(serverCreditDays);
    });
    axios.get(`${API_URL}/crud/customer`, apiAccess).then((response: any) => {
      setCustomers(response.data);
    });
  }, [cookie]);

  /**
   * Handle product update
   */
  useEffect(() => {}, [customer]);

  /**
   * Handle modal
   */
  useEffect(() => {
    if (similarCustomers.length > 0) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [similarCustomers]);

  /**
   * Handles inputs changes
   * @param event Input event
   * @param field Field to be affected
   */
  const handleInputChange = (event: any, field: string) => {
    setCustomer({ ...customer, [field]: event.target.value });
  };

  /**
   * Handles comboboxes changes
   * @param event Combobox event
   * @param field Field to be affected
   */
  const handleComboBoxChange = (event: any, field: string) => {
    switch (field) {
      case "customerTypeId":
        for (let i = 0; i < customerTypes.length; i++) {
          if (customerTypes[i].props.text === event.target.value.toString()) {
            setCustomer({
              ...customer,
              [field]: parseInt(customerTypes[i].key),
            });
            break;
          }
        }
        break;
      case "sellerId":
        for (let i = 0; i < employees.length; i++) {
          if (employees[i].props.text === event.target.value.toString()) {
            setCustomer({ ...customer, [field]: parseInt(employees[i].key) });
            break;
          }
        }
        break;
      case "creditDaysId":
        for (let i = 0; i < creditDays.length; i++) {
          if (creditDays[i].props.text === event.target.value.toString()) {
            setCustomer({ ...customer, [field]: parseInt(creditDays[i].key) });
            break;
          }
        }
        break;
      case "acceptCheck":
      case "saleInDollars":
      case "foreignCustomer":
      case "withholdVat":
        const cleanValue = event.target.value === "SI" ? true : false;
        setCustomer({ ...customer, [field]: cleanValue });
        break;
    }
  };

  /**
   * Sets the current customers type
   * @returns Current customers type
   */
  const getSelectedCustomerType = (): string => {
    if (customer.customerTypeId === 0) {
      return "";
    }
    for (let i = 0; i < customerTypes.length; i++) {
      if (customerTypes[i].key === customer.customerTypeId?.toString()) {
        return customerTypes[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current customers employee
   * @returns Current customers employee
   */
  const getSelectedEmployee = (): string => {
    if (customer.sellerId === 0) {
      return "";
    }
    for (let i = 0; i < employees.length; i++) {
      if (employees[i].key === customer.sellerId.toString()) {
        return employees[i].props.text;
      }
    }
    return "";
  };

  /**
   * Sets the current customers credit days
   * @returns Current customers credit days
   */
  const getSelectedCreditDays = (): string => {
    if (customer.creditDaysId === 0) {
      return "";
    }
    for (let i = 0; i < creditDays.length; i++) {
      if (creditDays[i].key === customer.creditDaysId.toString()) {
        return creditDays[i].props.text;
      }
    }
    return "";
  };

  /**
   * Searches the customer by sat
   * @param nit Customers nit
   * @returns Customers data
   */
  const findCustomerBySat = async (nit: any) => {
    try {
      const response = await axios.get(
        `${API_URL}/external/sat/${nit}`,
        apiAccess
      );
      return [response.data.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  const findCustomerByCui = async (cui: any) => {
    try {
      const response = await axios.get(
        `${API_URL}/customer/getCustomerCui/${cui}`,
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Searches the customer by internal
   * @param nit Customers nit
   * @returns Customers data
   */
  const findCustomerByInternal = async (nit: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/find-customer-by-nit`,
        {
          nit: nit,
        },
        apiAccess
      );
      return [response.data.rows, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Maps customer data
   * @param findBySatData Customers sat data
   * @returns Customers clean data
   */
  const satOnly = (findBySatData: any): CustomerDTO => {
    return {
      customerId: 0,
      nit: findBySatData.nit,
      fullName: findBySatData.nombre,
      address: "",
      phone: "",
      internalCode: "",
      email: "",
      customerTypeId: 0,
      sellerId: 0,
      deliveryAddress: "",
      acceptCheck: false,
      saleInDollars: false,
      foreignCustomer: false,
      withholdVat: false,
      limitCredit: 0.0,
      maximumDiscount: 0.0,
      creditDaysId: 0,
      about: "",
      dpi: "",
      passportid: "",
    };
  };

  const cuiOnly = (customerByCui: any): CustomerDTO => {
    return { ...customerInitialState, ...customerByCui };
  };

  /**
   * Maps customer data
   * @param findByInternalData Customers internal data
   * @returns Customers clean data
   */
  const satAndInternal = (findByInternalData: any): CustomerDTO => {
    return {
      customerId: findByInternalData[0].customerid,
      nit: findByInternalData[0].nit,
      fullName: findByInternalData[0].fullname,
      address: findByInternalData[0].address,
      phone: findByInternalData[0].phone,
      internalCode: findByInternalData[0].internalcode,
      email: findByInternalData[0].email ? findByInternalData[0].email : "",
      customerTypeId: findByInternalData[0].customertypeid,
      sellerId: findByInternalData[0].sellerid,
      deliveryAddress: findByInternalData[0].deliveryaddress,
      acceptCheck: findByInternalData[0].acceptcheck,
      saleInDollars: findByInternalData[0].saleindollars,
      foreignCustomer: findByInternalData[0].foreigncustomer,
      withholdVat: findByInternalData[0].withholdvat,
      limitCredit: findByInternalData[0].limitcredit,
      maximumDiscount: findByInternalData[0].maximumdiscount,
      creditDaysId: findByInternalData[0].creditdaysid,
      about: findByInternalData[0].about,
      dpi: findByInternalData[0].dpi ? findByInternalData[0].dpi : "",
      passportid: findByInternalData[0].passportid
        ? findByInternalData[0].passportid
        : "",
    };
  };

  /**
   *
   * @param customer
   */
  const getTransformedSimilarCustomer = (customers: any[]): any[] => {
    let resultCustomers: any[] = [];

    for (let i = 0; i < customers.length; i++) {
      resultCustomers.push({
        nit: customers[i].nit,
        dpi: customers[i].dpi,
        passportid: customers[i].passportid,
        fullname: customers[i].fullname,
        email: customers[i].email,
        phone: customers[i].phone,
      });
    }

    return resultCustomers;
  };

  /**
   * Finds customer by nit
   */
  const findCustomerByNit = async () => {
    const [findByInternalData] = await findCustomerByInternal(customerSearch);
    if (findByInternalData && findByInternalData.length > 0) {
      setCustomer(satAndInternal(findByInternalData));
      setIdCustomerType(1);
      return;
    }

    const [findBySatData] = await findCustomerBySat(customerSearch);

    if (findBySatData) {
      // needs to look for same fullname here
      await findCustomerByName(findBySatData.nombre)
        .then((localCustomer) => {
          setSimilarCustomers(localCustomer);
        })
        .catch((error) => {
          console.log("error trying to get local customer", error);
        });

      setCustomer(satOnly(findBySatData));
      setIdCustomerType(2);
      return;
    }

    const [customerCui] = await findCustomerByCui(customerSearch);

    if (customerCui) {
      // needs to look for same fullname here
      await findCustomerByName(customerCui.fullName)
        .then((localCustomer) => {})
        .catch((error) => {
          console.log("error trying to get local customer", error);
        });

      setCustomer(cuiOnly(customerCui));
      setIdCustomerType(2);
      return;
    } else {
      setIdCustomerType(0);
      setCustomer(customerInitialState);
      if (customerSearch.length > 4) {
        setOpenDialog(true);
      }
    }
  };

  /**
   * Gets customer data by id
   * @param customerId Customers id
   * @returns Customer data or an error
   */
  const findCustomerByIdRequest = async (customerId: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/query/find-customer-by-id`,
        {
          customerId: customerId,
        },
        apiAccess
      );
      return [response.data.rows, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Finds customer by id
   */
  const findCustomerById = async () => {
    const customerFinalId = customers.find(
      (customer: any) => customer.customerid === customerId
    );

    const [customerData, customerErrorData] = await findCustomerByIdRequest(
      customerFinalId.customerid
    );
    if (customerErrorData) {
      displayMessage("Error", "El cliente no se encuentra en el sistema");
      return;
    }
    setCustomer(satAndInternal(customerData));
    setIdCustomerType(2);
  };

  /**
   * Saves the current customer
   * @param customer Customer data
   * @returns Customers save data
   */
  const saveInternalCustomer = async (customer: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/customer/create`,
        customer,
        apiAccess
      );

      return [response.data.data[0], null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Updates the current customer
   * @param customer Customer data
   * @returns Customers update data
   */
  const updateInternalCustomer = async (customer: any) => {
    try {
      const response = await axios.put(
        `${API_URL}/customer/updateCustomer`,
        customer,
        apiAccess
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  };

  /**
   * Cleans customer save data
   * @param currentCustomerValue Customer data
   * @returns Customers clean save data
   */
  const customerSaveData = (currentCustomerValue: any) => {
    const attributes = Object.keys(currentCustomerValue);
    let finalData: any = {};
    for (let i = 0; i < attributes.length; i++) {
      finalData[attributes[i]] = currentCustomerValue[attributes[i]];
    }
    delete finalData.search;
    delete finalData.customerId;
    return finalData;
  };

  /**
   * Cleans customer update data
   * @param currentCustomerValue Customer data
   * @returns Customers update save data
   */
  const customerUpdateData = (currentCustomerValue: any) => {
    const attributes = Object.keys(currentCustomerValue);
    let finalData: any = {};
    for (let i = 0; i < attributes.length; i++) {
      finalData[attributes[i]] = currentCustomerValue[attributes[i]];
    }
    delete finalData.search;
    delete finalData.customerId;
    return {
      new: finalData,
      filter: {
        customerId: currentCustomerValue.customerId,
      },
    };
  };

  /**
   * Verifies customer data integrity
   * @returns True if the customers data is valid
   */
  const verifyCustomerIntegrity = async () => {
    if (
      customer.nit === "" &&
      customer.dpi === "" &&
      customer.passportid === ""
    ) {
      displayMessage(
        "Error",
        "Ingresa el número de identificación del cliente"
      );
      return false;
    }
    if (customer.fullName === "") {
      displayMessage("Error", "Ingresa el nombre del cliente");
      return false;
    }
    if (customer.address === "") {
      displayMessage("Error", "Ingresa la dirección del cliente");
      return false;
    }
    if (customer.phone === "") {
      displayMessage("Error", "Ingresa el teléfono del cliente");
      return false;
    }
    if (customer.customerTypeId === 0) {
      displayMessage("Error", "Ingresa el tipo de cliente");
      return false;
    }
    if (customer.sellerId === 0) {
      displayMessage("Error", "Ingresa el vendedor asignado al cliente");
      return false;
    }
    if (customer.deliveryAddress === "") {
      displayMessage("Error", "Ingresa la dirección de entrega del cliente");
      return false;
    }
    if (customer.creditDaysId === 0) {
      displayMessage("Error", "Ingresa el tipo de crédito del cliente");
      return false;
    }
    if (customer.about === "") {
      displayMessage("Error", "Ingresa las notas sobre crédito del cliente");
      return false;
    }
    return true;
  };

  /**
   * Save customer orchestrator
   */
  const saveCustomer = async () => {
    const currentCustomerValue = customer;
    if (currentCustomerValue.customerId === 0) {
      if (!(await verifyCustomerIntegrity())) return;

      const [saveCustomerData, saveCustomerError] = await saveInternalCustomer(
        customerSaveData(currentCustomerValue)
      );

      if (saveCustomerError) {
        displayMessage(
          "Error al crear cliente",
          "Verifica los datos e intenta nuevamente."
        );
        return;
      }
      setCustomer({ ...customer, customerId: saveCustomerData.customerid });
      setCustomerSearch(saveCustomerData.customerid);
      displayMessage(
        "Cliente creado",
        `Cliente #${saveCustomerData.customerid} creado.`,
        "success"
      );
    } else {
      if (!(await verifyCustomerIntegrity())) return;
      const [, updateCustomerError] = await updateInternalCustomer(
        customerUpdateData(currentCustomerValue)
      );
      if (updateCustomerError) {
        displayMessage(
          "Error al actualizar cliente",
          "Verifica los datos e intenta nuevamente."
        );
        return;
      }
      displayMessage(
        "Cliente actualizado",
        `Cliente #${currentCustomerValue.customerId} actualizado.`,
        "success"
      );
    }
  };

  /**
   *
   * @param fullname
   * @returns list of customer that match with fullname
   */
  const findCustomerByName = async (fullname: string): Promise<any[]> => {
    let result: any[] = [];
    if(!!!fullname) {
      return result;
    }
    await axios
      .get(`${API_URL}/customer/lookForSimilarNames/${fullname}`, apiAccess)
      .then((success) => {
        result = [...success.data];
      })
      .catch((error) => {
        setMessageContent(
          "Se ha producido un error inesperado al intentar corroborar la Información del cliente"
        );
        setOpenNotification(true);
        setSeverity("warning");
      });
    return result;
  };

  /**
   * Displays a dialog message
   * @param title Message title
   * @param content Message content
   */
  const displayMessage = (
    title: string,
    content: string,
    serverity = "error"
  ) => {
    setMessageContent(content);
    setOpenNotification(true);
    setSeverity(serverity);
    showMessage.current?.show();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleForeignCustomerConfirmation = () => {
    setOpenDialog(false);
    setCustomer({ ...customerInitialState, passportid: customerSearch });
    setIdCustomerType(3);
  };

  const handleCreateNewOne = () => {
    setOpenModal(false);
    setSimilarCustomers([]);
  };

  const handleOnEditSimilarCustomer = (index: number) => {
    const temporal: any[] = [];
    temporal.push(similarCustomers[index]);
    setCustomer(satAndInternal(temporal));
    setIdCustomerType(1);
    setOpenDialog(false);
    setSimilarCustomers([]);
  };

  return (
    <div className={styles.customer}>
      <Form
        titleText="Búsqueda"
        columnsXL={2}
        columnsL={2}
        columnsM={1}
        columnsS={1}
      >
        <FormItem label="NIT">
          <Input
            icon={<Icon name="number-sign" />}
            onInput={(e) => setCustomerSearch(e.target.value.toString())}
            className={styles.input}
            value={customerSearch}
          />
        </FormItem>
        <FormItem label="">
          <Button
            endIcon={<SearchIcon />}
            onClick={() => findCustomerByNit()}
            className={styles.button}
            variant="outlined"
          >
            Consultar
          </Button>
        </FormItem>
        <FormItem label={"Nombre"}>
          <Autocomplete
            className={styles.input}
            size="small"
            options={customers}
            getOptionLabel={(option) => option.fullname}
            filterSelectedOptions
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.customerid}>
                {option.fullname}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e: any, newValue: any) => {
              if (newValue) {
                setCustomerId(newValue.customerid);
              }
            }}
          />
        </FormItem>
        <FormItem label="">
          <Button
            endIcon={<SearchIcon />}
            onClick={() => findCustomerById()}
            className={styles.button}
            variant="outlined"
          >
            Consultar
          </Button>
        </FormItem>
      </Form>
      <br />
      {idCustomerType > 0 && (
        <Form
          titleText="Información del Cliente"
          columnsXL={1}
          columnsL={1}
          columnsM={1}
          columnsS={1}
        >
          <FormGroup titleText="Principal">
            <FormItem label="NIT">
              <Input
                icon={<Icon name="number-sign" />}
                onInput={(e) => {
                  handleInputChange(e, "nit");
                }}
                className={styles.input}
                value={customer.nit}
                disabled={idCustomerType > 1}
              />
            </FormItem>

            <FormItem label="CUI">
              <Input
                icon={<Icon name="number-sign" />}
                onInput={(e) => handleInputChange(e, "dpi")}
                className={styles.input}
                value={customer.dpi}
                disabled={idCustomerType > 1}
              />
            </FormItem>
            <FormItem label="NIT EXT">
              <Input
                icon={<Icon name="number-sign" />}
                onInput={(e) => handleInputChange(e, "passportid")}
                className={styles.input}
                value={customer.passportid}
                disabled={idCustomerType === 2}
              />
            </FormItem>
            <FormItem label="Nombre">
              <Input
                icon={<Icon name="customer" />}
                onInput={(e) => handleInputChange(e, "fullName")}
                className={styles.input}
                value={customer.fullName}
                disabled={idCustomerType === 2}
                style={{
                  border: customer.fullName === "" ? "2px solid red" : "",
                }}
              />
            </FormItem>
            <FormItem label="Dirección">
              <Input
                icon={<Icon name="addresses" />}
                onInput={(e) => handleInputChange(e, "address")}
                className={styles.input}
                value={customer.address}
                style={{
                  border: customer.address === "" ? "2px solid red" : "",
                }}
              />
            </FormItem>
            <FormItem label="Teléfono">
              <Input
                type="Number"
                icon={<Icon name="phone" />}
                onInput={(e) => handleInputChange(e, "phone")}
                className={styles.input}
                value={customer.phone}
                style={{
                  border: customer.phone === "" ? "2px solid red" : "",
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup titleText="Interno">
            <FormItem label="Email">
              <Input
                icon={<Icon name="email" />}
                onInput={(e) => handleInputChange(e, "email")}
                className={styles.input}
                value={customer.email}
              />
            </FormItem>
            <FormItem label="Tipo">
              <ComboBox
                icon={<Icon name="customer" />}
                onChange={(e) => handleComboBoxChange(e, "customerTypeId")}
                value={getSelectedCustomerType()}
                className={styles.input}
                style={{
                  border: customer.customerTypeId === 0 ? "2px solid red" : "",
                }}
              >
                {customerTypes}
              </ComboBox>
            </FormItem>
            <FormItem label="Vendedor">
              <ComboBox
                icon={<Icon name="employee" />}
                onChange={(e) => handleComboBoxChange(e, "sellerId")}
                value={getSelectedEmployee()}
                className={styles.input}
                style={{
                  border: customer.sellerId === 0 ? "2px solid red" : "",
                }}
              >
                {employees}
              </ComboBox>
            </FormItem>
            <FormItem label="Dirección de Entrega">
              <Input
                icon={<Icon name="shipping-status" />}
                onInput={(e) => handleInputChange(e, "deliveryAddress")}
                className={styles.input}
                value={customer.deliveryAddress}
                style={{
                  border:
                    customer.deliveryAddress === "" ? "2px solid red" : "",
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup titleText="Crédito">
            <FormItem label="Aceptamos Cheque">
              <ComboBox
                icon={<Icon name="burglary" />}
                onChange={(e) => handleComboBoxChange(e, "acceptCheck")}
                className={styles.input}
                value={customer.acceptCheck ? "SI" : "NO"}
              >
                <ComboBoxItem text="SI" />
                <ComboBoxItem text="NO" />
              </ComboBox>
            </FormItem>
            <FormItem label="Venta en Dólares">
              <ComboBox
                icon={<Icon name="money-bills" />}
                onChange={(e) => handleComboBoxChange(e, "saleInDollars")}
                className={styles.input}
                value={customer.saleInDollars ? "SI" : "NO"}
              >
                <ComboBoxItem text="SI" />
                <ComboBoxItem text="NO" />
              </ComboBox>
            </FormItem>
            <FormItem label="Cliente Exterior">
              <ComboBox
                icon={<Icon name="world" />}
                onChange={(e) => handleComboBoxChange(e, "foreignCustomer")}
                className={styles.input}
                value={customer.foreignCustomer ? "SI" : "NO"}
              >
                <ComboBoxItem text="SI" />
                <ComboBoxItem text="NO" />
              </ComboBox>
            </FormItem>
            <FormItem label="Retiene IVA">
              <ComboBox
                icon={<Icon name="collections-insight" />}
                onChange={(e) => handleComboBoxChange(e, "withholdVat")}
                className={styles.input}
                value={customer.withholdVat ? "SI" : "NO"}
              >
                <ComboBoxItem text="SI" />
                <ComboBoxItem text="NO" />
              </ComboBox>
            </FormItem>
            <FormItem label="Límite de Crédito">
              <Input
                icon={<Icon name="lead" />}
                onInput={(e) => handleInputChange(e, "limitCredit")}
                className={styles.input}
                value={customer.limitCredit.toString()}
                type="Number"
              />
            </FormItem>
            <FormItem label="Descuento Máximo">
              <Input
                icon={<Icon name="loan" />}
                onInput={(e) => handleInputChange(e, "maximumDiscount")}
                className={styles.input}
                value={customer.maximumDiscount.toString()}
                type="Number"
              />
            </FormItem>
            <FormItem label="Días de Crédito">
              <ComboBox
                icon={<Icon name="general-leave-request" />}
                onChange={(e) => handleComboBoxChange(e, "creditDaysId")}
                className={styles.input}
                value={getSelectedCreditDays()}
                style={{
                  border: customer.creditDaysId === 0 ? "2px solid red" : "",
                }}
              >
                {creditDays}
              </ComboBox>
            </FormItem>
            <FormItem label="Notas sobre Crédito">
              <TextArea
                onInput={(e) => handleInputChange(e, "about")}
                value={customer.about}
                style={{
                  border: customer.about === "" ? "2px solid red" : "",
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup titleText="Confirmar">
            <FormItem label="">
              <Button
                startIcon={<PersonAddAlt1Icon />}
                onClick={() => saveCustomer()}
                className={styles.button}
                variant="contained"
                disabled={
                  customer.customerId !== 0 &&
                  userCookie.user.toString() !== "1"
                }
              >
                {customer.customerId === 0
                  ? "Crear Cliente"
                  : "Modificar Cliente"}
              </Button>
            </FormItem>
          </FormGroup>
        </Form>
      )}
      <SnackBar
        message={messageContent}
        open={openNotification}
        setOpen={setOpenNotification}
        severity={severity}
      />
      <DialogComponent
        dialogTitle={"¿Es un documento de identificación extranjero?"}
        dialogContent={
          "El número de identificación no se pudo autenticar con la SAT. ¿Corresponde a un documento de identificación extranjero?"
        }
        openDialog={openDialog}
        handleClose={handleClose}
        handleForeignCustomerConfirmation={handleForeignCustomerConfirmation}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#E7E6E7",
          }}
        >
          <p>
            Hemos detectado cliente(s) con datos similares a los que se han
            ingresado. Por favor corrobora si se trata de la misma persona.
            <br /> De ser asi, presiona en <b>Editar</b>.<br /> De lo contrario
            presiona en <b>'Registrar nuevo'</b> y continua con el registro.
          </p>
          <DynamicTable
            headers={["nit", "dpi", "Doc. Ext", "nombre", "correo", "numero"]}
            tableContent={getTransformedSimilarCustomer(similarCustomers)}
            tableActions={[
              {
                props: {
                  variant: "text",
                  color: "info",
                  text: "Editar",
                  size: "medium",
                  sx: { marginTop: "0.5rem" },
                },
                callback: (index: number) => handleOnEditSimilarCustomer(index),
              },
            ]}
          />
          <Button variant="contained" onClick={handleCreateNewOne}>
            Registrar nuevo
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Customer;
